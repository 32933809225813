import React, {
  MouseEventHandler,
  useContext,
  useRef,
  ChangeEvent,
  useMemo,
  useCallback,
} from 'react'

import { DateTime } from 'luxon'

import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import { IDriver, IDriverUpdatePayload } from '../../interfaces/Registration'
import TermsConditions from './TermsConditions'
import SignaturePad from 'react-signature-canvas'
import { convertDob } from '../../utils/RegistrationHelpers'
import {
  ADULT_CLEAR_SIGN_LABEL,
  EMAIL_CONSENT_TEXT,
  RISK_AGREEMENT_AGREED_TEXT,
  TERMS_CONDITIONS_AGREED_TEXT_1,
  TERMS_CONDITIONS_AGREED_TEXT_2,
  SCREEN_NAMES,
} from './Constants'
import { postRequest } from '../../api'
import Capitalize from '../../utils/Capitalize'
import isIDriver from '../../utils/isDriver'
import { sentryMessage } from '../../helper/sentry'

const JuniorTermsAndCondition = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const {
    dispatch,
    setLoading,
    driver,
    drivers,
    juniors,
    isGenericDriverRegistration,
    orderId,
    navigation,
    isJunior,
    hasError,
    metaobjectConfig,
  } = registrationContext
  const signRef = useRef<any>(null)

  const tcsAgreedHardCodedValue = ` <sup>*</sup>
          ${TERMS_CONDITIONS_AGREED_TEXT_1}
          <a
            className="underline"
            href="https://www.racefacer.com/en/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
          ,&nbsp;
          <a
            className="underline"
            href="https://www.racefacer.com/en/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          ${TERMS_CONDITIONS_AGREED_TEXT_2}`

  const tcsRiskAgreementHardCodedValue = `<sup>*</sup>
                  ${RISK_AGREEMENT_AGREED_TEXT}`
  const tcsAgreed = useMemo(
    () =>
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionAgreed || tcsAgreedHardCodedValue,
    [
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionAgreed,
      tcsAgreedHardCodedValue,
    ]
  )
  const tcsRiskAgreement = useMemo(
    () =>
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionRiskAgreement || tcsRiskAgreementHardCodedValue,
    [
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionRiskAgreement,
      tcsRiskAgreementHardCodedValue,
    ]
  )
  const tcsEmailConsent = useMemo(
    () =>
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionEmailConsent || EMAIL_CONSENT_TEXT,
    [
      metaobjectConfig?.generalConfig?.registrationSummary
        ?.termsAndConditionEmailConsent,
    ]
  )
  const filterJuniorDrivers: IDriver[] = useMemo(
    () =>
      [...juniors, ...drivers].filter(
        (driver: IDriver) =>
          driver.first_name &&
          driver.last_name &&
          driver.dob &&
          (DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').isValid ||
            DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').toMillis() >
              DateTime.now().toMillis() ||
            DateTime.fromFormat(driver.dob, 'dd-MM-yyyy').toMillis() <
              DateTime.now().minus({ years: 16, days: 1 }).toMillis()) &&
          driver.gender
      ),
    [drivers, juniors]
  ) as IDriver[]

  const submitButtonHandler: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      setLoading(true)

      try {
        // Prepare updated drivers and guardian details
        let _updateExistingDrivers: IDriverUpdatePayload[] = [
          ...drivers
            .filter(
              (existingDriver: IDriver | IDriverUpdatePayload) =>
                existingDriver.firebase_id &&
                existingDriver.racefacer_uuid &&
                isIDriver(existingDriver) &&
                existingDriver.guardian_dob &&
                existingDriver.guardian_first_name &&
                existingDriver.guardian_last_name
            )
            .map((existingDriver: IDriver | IDriverUpdatePayload) => {
              if (isIDriver(existingDriver)) {
                return {
                  racefacer_uuid: existingDriver.racefacer_uuid,
                  firebase_id: existingDriver.firebase_id,
                  isJunior: isJunior,
                  email: existingDriver.email,
                  data: {
                    first_name: Capitalize(existingDriver.first_name),
                    last_name: Capitalize(existingDriver.last_name),
                    dob: convertDob(existingDriver.dob as string),
                    gender: existingDriver.gender,
                    signature_url: driver?.signature_url,
                  } as IDriver,
                }
              }
              return existingDriver as IDriverUpdatePayload
            }),
        ] as IDriverUpdatePayload[]

        let _guardianDetails = drivers
          .filter(
            (existingDriver: IDriver | IDriverUpdatePayload) =>
              isIDriver(existingDriver) &&
              existingDriver.guardian_first_name &&
              existingDriver.guardian_last_name &&
              existingDriver.guardian_id &&
              existingDriver.guardian_dob
          )
          .map((existingDriver: IDriver | IDriverUpdatePayload) => {
            if (
              isIDriver(existingDriver) &&
              existingDriver.guardian_first_name &&
              existingDriver.guardian_last_name &&
              existingDriver.guardian_dob &&
              existingDriver.guardian_id
            ) {
              return {
                guardian_first_name: Capitalize(
                  existingDriver.guardian_first_name
                ),
                guardian_last_name: Capitalize(
                  existingDriver.guardian_last_name
                ),
                guardian_dob: convertDob(existingDriver.guardian_dob),
                guardian_id: existingDriver.guardian_id,
                email: existingDriver.email,
              }
            }
            return undefined
          })
          .find(Boolean)

        let _newDrivers =
          _updateExistingDrivers &&
          _updateExistingDrivers.length &&
          _guardianDetails
            ? [
                ...filterJuniorDrivers
                  .filter(
                    (filterDriver: IDriver) =>
                      !filterDriver.racefacer_uuid && !filterDriver.firebase_id
                  )
                  .map(junior => ({
                    first_name: Capitalize(junior.first_name),
                    last_name: Capitalize(junior.last_name),
                    dob: convertDob(junior.dob),
                    gender: junior.gender,
                    ..._guardianDetails,
                  })),
              ]
            : ([
                ...filterJuniorDrivers
                  .filter(
                    (filterDriver: IDriver) =>
                      !filterDriver.racefacer_uuid && !filterDriver.firebase_id
                  )
                  .map(junior => ({
                    ...driver,
                    first_name: Capitalize(junior.first_name),
                    last_name: Capitalize(junior.last_name),
                    dob: convertDob(junior.dob),
                    guardian_dob:
                      driver && convertDob(driver.guardian_dob as string),
                    email_consent: driver && driver.email_consent,
                    gender: junior.gender,
                    city_id: 2284,
                    country: 'AU',
                    city: 'Sydney',
                    isJunior: true,
                  })),
              ] as IDriver[])

        if (_newDrivers && _newDrivers.length !== 0) {
          _newDrivers = _newDrivers.map((driver: any) => {
            if (
              driver.racefacer_uuid &&
              driver.firebase_id &&
              driver.guardian_id &&
              driver.juniorSelectedDOB &&
              driver.juniorDOBErrorMesssage
            ) {
              const {
                racefacer_uuid,
                firebase_id,
                guardian_id,
                juniorSelectedDOB,
                juniorDOBErrorMesssage,
                ...rest
              } = driver
              return rest as IDriver
            }
            return driver
          })
        }

        let registerDrivers: IDriver[] = [] as IDriver[]

        // Create new drivers
        if (_newDrivers && _newDrivers.length !== 0) {
          const requestURL = 'createDrivers'

          try {
            const { data } = await postRequest(requestURL, {
              data: {
                drivers: _newDrivers,
                isJunior: true,
              },
            })

            let newDriverResponse = data.result.drivers
            registerDrivers = [...registerDrivers, ...newDriverResponse]
          } catch (error) {
            sentryMessage(
              'JuniorTermAndConditions: createDrivers API Error',
              'error',
              {
                error,
                _newDrivers,
                requestURL,
              }
            )
            throw error
          }
        }

        // Update existing drivers
        if (_updateExistingDrivers && _updateExistingDrivers.length !== 0) {
          const requestURL = 'updateDriversV2'

          try {
            const { data } = await postRequest(requestURL, {
              data: {
                drivers: _updateExistingDrivers,
              },
            })

            let newDriverResponse = data.result.drivers
            registerDrivers = [...registerDrivers, ...newDriverResponse]
          } catch (error) {
            sentryMessage(
              'JuniorTermAndConditions: updateDriversV2 API Error',
              'error',
              {
                error,
                _updateExistingDrivers,
                requestURL,
              }
            )
            throw error
          }
        }

        // Add drivers to booking (if applicable)
        if (!isGenericDriverRegistration) {
          try {
            const res = await postRequest('addDriverToBooking', {
              data: {
                drivers: registerDrivers,
                orderId: orderId,
              },
            })

            const {
              data: {
                result: { drivers },
              },
            } = res

            dispatch({
              type: 'SET_DRIVERS',
              payload: {
                drivers: drivers as IDriver[],
              },
            })
          } catch (error) {
            sentryMessage(
              'JuniorTermAndConditions: addDriverToBooking API Error',
              'error',
              {
                error,
                registerDrivers,
                orderId,
              }
            )
            throw error
          }
        }

        // Navigate to the success screen
        dispatch({
          type: 'SET_CURRENT_NAVIGATION',
          payload: {
            navigation: {
              currentScreen: SCREEN_NAMES.SUCCESS_SCREEN,
              previousScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
            },
          },
        })
      } catch (error) {
        sentryMessage('JuniorTermAndConditions: Unexpected Error', 'error', {
          error,
        })

        alert(
          'An error occurred while processing your request. Please try again.'
        )
      } finally {
        setLoading(false)
      }
    }, [
      dispatch,
      driver,
      drivers,
      filterJuniorDrivers,
      isGenericDriverRegistration,
      isJunior,
      orderId,
      setLoading,
    ])

  const handleEmailConsent = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: { ...driver, email_consent: event.target.checked } as IDriver,
      },
    })
  }

  const handleTCSAgreed = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: { ...driver, tcs_agreed: event.target.checked } as IDriver,
      },
    })
  }

  const handleRiskAgreementAgreed = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: {
          ...driver,
          risk_agreement_agreed: event.target.checked,
        } as IDriver,
      },
    })
  }

  const clearSignature = () => {
    signRef?.current?.clear()
    dispatch({
      type: 'SET_REGISTRATION_DRIVER',
      payload: {
        driver: {
          ...driver,
          signature_url: '',
        } as IDriver,
      },
    })
  }

  const isButtonDisabled = Boolean(
    driver &&
      driver.tcs_agreed &&
      driver.risk_agreement_agreed &&
      driver.signature_url &&
      filterJuniorDrivers.length &&
      !hasError
  )

  return (
    <>
      <TermsConditions />
      <div className="text-[14px] sm:text-[16px] font-[700] mb-3 font-semibold text-left text-[#2F1160]">
        Please Sign Here
      </div>
      <div className="h-[200px]">
        <SignaturePad
          penColor="black"
          clearOnResize={false}
          canvasProps={{
            className:
              'sigCanvas outline-0 text-base border rounded-xl resize-none mb-5 w-full h-full',
          }}
          ref={signRef}
          onEnd={() => {
            dispatch({
              type: 'SET_REGISTRATION_DRIVER',
              payload: {
                driver: {
                  ...driver,
                  signature_url: signRef?.current.toDataURL(),
                } as IDriver,
              },
            })
          }}
        />
      </div>
      <div className="mb-4 flex" onClick={clearSignature}>
        {/* {<p className="text-red-500 text-sm">{errors.signature_url}</p>} */}
        <div className="text-sm underline text-slate-500 pr-1 cursor-pointer w-max ml-auto">
          {ADULT_CLEAR_SIGN_LABEL}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="tcs_agreed"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleTCSAgreed(event)
          }
          checked={driver && driver.tcs_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="tcs_agreed"
        />
        {driver && driver.tcs_agreed ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      tcs_agreed: !driver.tcs_agreed,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      tcs_agreed: !driver.tcs_agreed,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="tcs_agreed"
          className="text-slate-500 text-sm font-normal sm:max-w-[calc(100%_-_1.625rem)] max-w-[calc(100%_-_2.625rem)] cursor-pointer"
          dangerouslySetInnerHTML={{ __html: tcsAgreed as string }}
        />
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="risk_agreement_agreed"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleRiskAgreementAgreed(event)
          }
          checked={driver && driver.risk_agreement_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="risk_agreement_agreed"
        />
        {driver && driver.risk_agreement_agreed ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      risk_agreement_agreed: !driver.risk_agreement_agreed,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      risk_agreement_agreed: !driver.risk_agreement_agreed,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="risk_agreement_agreed"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)] cursor-pointer"
          dangerouslySetInnerHTML={{ __html: tcsRiskAgreement as string }}
        />
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="email_consent"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleEmailConsent(event)
          }
          checked={driver && driver.email_consent}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer hidden"
          id="email_consent"
        />
        {driver && driver.email_consent ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              className="mr-2.5 cursor-pointer"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      email_consent: !driver.email_consent,
                    } as IDriver,
                  },
                })
              }
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="2.5"
                fill="#2EBF3C"
                stroke="#555758"
              />
              <path
                d="M5 7.875L6.875 9.75L10.625 6"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </>
        ) : (
          <>
            <div
              className="w-[16px] h-[16px] rounded-[3px] border-[1px] border-[#555758] mr-2.5 cursor-pointer"
              onClick={() =>
                driver &&
                dispatch({
                  type: 'SET_REGISTRATION_DRIVER',
                  payload: {
                    driver: {
                      ...driver,
                      email_consent: !driver.email_consent,
                    } as IDriver,
                  },
                })
              }
            ></div>
          </>
        )}
        <label
          htmlFor="email_consent"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)] cursor-pointer"
          dangerouslySetInnerHTML={{ __html: tcsEmailConsent }}
        />
      </div>
      <p className="text-red-500 text-sm">
        {driver &&
          (!driver.tcs_agreed || !driver.risk_agreement_agreed) &&
          driver.signature_url === '' &&
          'Please sign and accept the Terms and Conditions'}
      </p>
      <div className="flex justify-between items-center gap-[10px]">
        <button
          className={`w-fit self-center items-center border-[2px] border-[#9D62FE] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-[#9D62FE] w-full`}
          onClick={() =>
            navigation.previousScreen === SCREEN_NAMES.DRIVER_PREVIEW_SCREEN
              ? dispatch({
                  type: 'SET_CURRENT_NAVIGATION',
                  payload: {
                    navigation: {
                      ...navigation,
                      currentScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
                    },
                  },
                })
              : dispatch({
                  type: 'SET_CURRENT_NAVIGATION',
                  payload: {
                    navigation: {
                      ...navigation,
                      currentScreen: SCREEN_NAMES.DRIVER_LIST,
                    },
                  },
                })
          }
        >
          Back
        </button>
        <button
          className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white ${
            !isButtonDisabled
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer'
          }`}
          onClick={submitButtonHandler}
          disabled={!isButtonDisabled}
        >
          <span className="hidden sm:block">Complete Registration</span>
          <span className="sm:hidden">Complete</span>
        </button>
      </div>
    </>
  )
}

export default JuniorTermsAndCondition
