import { IDriver } from '../interfaces/Registration'

/**
 * Validates the required fields of an IDriver object.
 * @param driver - The driver object to validate.
 * @returns An object containing a boolean `isValid` and an array of `errors` (if any).
 */

export const validateDriverFields = (
  driver: IDriver
): { isValid: boolean; errors: string[] } => {
  const errors: string[] = []

  // Required fields and their validation rules
  const requiredFields = [
    { field: 'email', message: 'Email is required' },
    { field: 'first_name', message: 'First name is required' },
    { field: 'last_name', message: 'Last name is required' },
    { field: 'dob', message: 'Date of birth is required' },
    { field: 'phone_number', message: 'Phone number is required' },
    { field: 'address', message: 'Address is required' },
    { field: 'zip_code', message: 'Zip code is required' },
    { field: 'tcs_agreed', message: 'Terms and conditions must be agreed to' },
    {
      field: 'risk_agreement_agreed',
      message: 'Risk agreement must be agreed to',
    },
    { field: 'signature_url', message: 'Signature is required' },
  ]

  requiredFields.forEach(({ field, message }) => {
    const value = driver[field as keyof IDriver]

    if (value === undefined || value === null || value === '') {
      errors.push(message)
    } else if (typeof value === 'boolean' && !value) {
      errors.push(message)
    }
  })

  // Additional validation for junior drivers
  if (driver.isJunior) {
    if (
      !driver.guardian_first_name ||
      !driver.guardian_last_name ||
      !driver.guardian_dob
    ) {
      errors.push('Guardian details are required for junior drivers')
    }
  }

  return {
    isValid: errors.length === 0,
    errors,
  }
}
