import axios from 'axios'
export const getRequest = (route: string, params: any) => {
  return axios({
    url: process.env.REACT_APP_API + route,
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: `Bearer ${localStorage['authenticate-token']}`,
    },
    params,
  })
}

// error handling improvement
export const postRequest = async (route: string, data: any) => {
  try {
    const response = await axios({
      url: process.env.REACT_APP_API + route,
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //Authorization: `Bearer ${localStorage['authenticate-token']}`,
      },
      data,
    })
    return response
  } catch (error) {
    // Throw the error so it can be caught and handled by the calling function
    throw error
  }
}
