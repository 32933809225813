import { useContext, useEffect, useState, useMemo } from 'react'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import {
  SCREEN_NAMES,
  SELECT_YOUR_SESSION_HEADER,
  SELECT_YOUR_SESSION_IS_FULL,
  SELECT_YOUR_SESSION_SUB_HEADER_TWO,
  SELECT_YOUR_SESSION_ZERO_REGISTRATED_DRIVER,
} from './Constants'
import {
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid'
import Header from './Header'
import { IOrderLineItem } from 'shopify-api-node'
import { getDatetimeFromSku } from '../../utils/getDateFromSku'

interface ISelectableLineItemsProps {
  lineItems: IOrderLineItem[]
}

const SelectableLineItems: React.FC<ISelectableLineItemsProps> = ({
  lineItems,
}) => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, selectedSession, booking, metaobjectConfig } =
    registrationContext
  const { drivers, selfCheckIn } = booking
  const [collapsedItems, setCollapsedItems] = useState<Record<number, boolean>>(
    {}
  )

  const noDriversNote = useMemo(
    () =>
      (metaobjectConfig &&
        metaobjectConfig?.adultFlowConfig?.sessionListsScreen?.noDriversNote) ||
      SELECT_YOUR_SESSION_ZERO_REGISTRATED_DRIVER,
    [metaobjectConfig]
  )

  const sessionIsFullMessage = useMemo(
    () =>
      (metaobjectConfig &&
        metaobjectConfig?.adultFlowConfig?.sessionListsScreen
          ?.sessionIsFullNote) ||
      SELECT_YOUR_SESSION_IS_FULL,
    [metaobjectConfig]
  )

  const toggleCollapse = (item: IOrderLineItem, event: React.MouseEvent) => {
    event.stopPropagation()

    const isAlreadyOpen = collapsedItems[item.id] || false

    setCollapsedItems(isAlreadyOpen ? {} : { [item.id]: true })
  }
  const handleSessionSelection = (
    item: IOrderLineItem,
    event: React.MouseEvent
  ) => {
    event.stopPropagation()
    const isItemSelected = selectedSession.find(
      session => session.id === item.id
    )

    if (isItemSelected) {
      const updatedSession = selectedSession.filter(
        session => session.id !== item.id
      )
      dispatch({
        type: 'SET_SELECTED_SESSION',
        payload: { selectedSession: updatedSession },
      })
    } else {
      dispatch({
        type: 'SET_SELECTED_SESSION',
        payload: { selectedSession: [...selectedSession, item] },
      })
    }
  }

  const filterLineItems: IOrderLineItem[] = useMemo(() => {
    if (lineItems && lineItems.length > 0) {
      return lineItems
        .filter(
          (line: IOrderLineItem) =>
            line.title.toLowerCase() !== 'booking protection'
        )
        .filter(
          filterItem =>
            filterItem?.sku?.includes('.R10') ||
            filterItem?.sku?.includes('.R15')
        )
    }

    return []
  }, [lineItems])

  return (
    <>
      {filterLineItems.length > 0 &&
        filterLineItems.map(item => {
          let registeredDriversCount = 0
          const registeredDrivers: {
            driverId: string
            orderId: string
            sku: string
          }[] = [] as {
            driverId: string
            orderId: string
            sku: string
          }[]

          if (selfCheckIn?.length) {
            selfCheckIn.forEach(eachItem => {
              if (eachItem.sku === item.sku) {
                registeredDriversCount += 1
                registeredDrivers.push(eachItem)
              }
            })
          }

          const isCollapsed = collapsedItems[item.id] || false

          return (
            <div key={item.id} className="w-full">
              <div
                className={`mb-2.5 px-5 py-4 w-full border-[2px] border-solid ${
                  selectedSession &&
                  selectedSession.some(someItem => someItem.id === item.id)
                    ? 'border-[#5B36DF] bg-[#b4a4e0] cursor-pointer'
                    : 'border-inherit cursor-pointer'
                } rounded-lg`}
                onClick={event =>
                  registeredDriversCount <= item.quantity
                    ? toggleCollapse(item, event)
                    : alert(sessionIsFullMessage)
                }
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <strong>
                      {item.title.includes('|')
                        ? item.title.split('|')[0]
                        : item.title}{' '}
                      | {getDatetimeFromSku(item.sku)?.toFormat('hh:mma')}
                    </strong>
                    <span>
                      {registeredDriversCount}/{item.quantity} Seats
                      {registeredDriversCount >= item.quantity && (
                        <span className="text-[red] block">
                          {sessionIsFullMessage}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between items-center gap-2">
                    <button
                      onClick={event =>
                        registeredDriversCount >= item.quantity
                          ? alert(sessionIsFullMessage)
                          : handleSessionSelection(item, event)
                      }
                      className={`px-2 rounded-full font-semibold ${
                        selectedSession &&
                        selectedSession.some(
                          someItem => someItem.id === item.id
                        )
                          ? 'border border-[#5B36DF] text-[#5B36DF] hover:text-[red] hover:border-[red]'
                          : 'border border-[#5B36DF] text-[#5B36DF]'
                      }`}
                    >
                      {selectedSession &&
                      selectedSession.some(someItem => someItem.id === item.id)
                        ? 'Unselect'
                        : 'Select'}
                    </button>
                    {isCollapsed ? (
                      <ChevronUpIcon className="w-[22px] h-[22px]" />
                    ) : (
                      <ChevronDownIcon className="w-[22px] h-[22px]" />
                    )}
                  </div>
                </div>

                {isCollapsed &&
                drivers?.length > 0 &&
                registeredDrivers.length > 0 ? (
                  <div className="mt-2 max-h-80 h-full overflow-y-auto">
                    {drivers
                      .filter(driver =>
                        registeredDrivers.some(
                          reg => reg.driverId === driver.firebase_id
                        )
                      )
                      .map((driver, index) => (
                        <div
                          key={driver.firebase_id}
                          className="py-2 px-4 border border-[black] rounded-md mb-2"
                        >
                          <span className="block font-bold">
                            #{index + 1} {driver.first_name} {driver.last_name}
                          </span>
                        </div>
                      ))}
                  </div>
                ) : (
                  isCollapsed && (
                    <div className="mt-2 py-2 px-4 border border-[black] rounded-md mb-2">
                      <span className="block font-bold text-red">
                        {noDriversNote}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          )
        })}
    </>
  )
}

const SelectYourSessionLists = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, order, selectedSession, metaobjectConfig } =
    registrationContext

  const [lineItems, setLineItems] = useState<IOrderLineItem[]>([])

  useEffect(() => {
    if (order?.line_items) {
      setLineItems(order.line_items)
    }
  }, [order])

  const componentHeader = useMemo(
    () =>
      (metaobjectConfig &&
        metaobjectConfig?.adultFlowConfig?.sessionListsScreen?.title) ||
      SELECT_YOUR_SESSION_HEADER,
    [metaobjectConfig]
  )
  const componentSubHeader = useMemo(
    () =>
      (metaobjectConfig &&
        metaobjectConfig?.adultFlowConfig?.sessionListsScreen?.description) ||
      SELECT_YOUR_SESSION_SUB_HEADER_TWO,
    [metaobjectConfig]
  )

  return (
    <>
      <button
        className="text-[#5B36DF] font-semibold absolute top-4 left-5 flex items-center"
        onClick={() =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                currentScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_OPTIONS,
                previousScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS,
              },
            },
          })
        }
      >
        <ChevronLeftIcon className="w-[22px] h-[22px]" /> Go Back
      </button>

      <Header title={componentHeader} description={componentSubHeader} />

      {lineItems.length > 0 && (
        <div className="flex items-center justify-between sm:mb-7 mb-10 flex-wrap">
          <SelectableLineItems lineItems={lineItems} />
        </div>
      )}

      <div className="flex justify-center items-center gap-3">
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CURRENT_NAVIGATION',
              payload: {
                navigation: {
                  currentScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_OPTIONS,
                  previousScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS,
                },
              },
            })
          }
          className="w-full text-[#5B36DF] font-semibold py-[15px] px-[25px] capitalize text-[16px] border border-[#5B36DF] rounded-full"
        >
          back
        </button>
        <button
          onClick={() => {
            if (selectedSession && selectedSession.length > 0) {
              dispatch({
                type: 'SET_CURRENT_NAVIGATION',
                payload: {
                  navigation: {
                    previousScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_LISTS,
                    currentScreen: SCREEN_NAMES.SELECT_YOUR_SESSION_SELECTED,
                  },
                },
              })
            }
          }}
          className={`w-full font-semibold py-[15px] px-[25px] capitalize text-[16px] rounded-full ${
            selectedSession && selectedSession.length > 0
              ? 'bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] text-white cursor-pointer'
              : 'bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] opacity-50 cursor-not-allowed'
          }
            `}
        >
          Continue
        </button>
      </div>
    </>
  )
}

export default SelectYourSessionLists
